import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db05d6f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price-item mr-4 relative" }
const _hoisted_2 = { class: "price-item__value" }
const _hoisted_3 = { class: "price-item__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formatPrice(_ctx.price)) + " ₽", 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
    (_ctx.tooltip)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          tooltip: _ctx.tooltip
        }, null, 8, ["tooltip"]))
      : _createCommentVNode("", true)
  ]))
}