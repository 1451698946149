import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1156b26d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay justify-center"
}
const _hoisted_2 = { class: "modal" }
const _hoisted_3 = { class: "modal-title mb-4" }
const _hoisted_4 = { class: "modal-description" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "space-y-5" }
const _hoisted_7 = { class: "absolute top-full text-error z-[1] text-xs" }
const _hoisted_8 = { class: "mt-5 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UInput = _resolveComponent("UInput")!
  const _component_UButton = _resolveComponent("UButton")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "absolute modal-close-btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, "+"),
          _createElementVNode("header", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.message), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createVNode(_component_UInput, {
                  class: "!max-w-none flex-grow",
                  label: "Номер телефона",
                  value: _ctx.username,
                  modelValue: _ctx.username,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event)),
                  mask: "phone"
                }, {
                  icon: _withCtx(() => [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.usernameError), 1)
                  ]),
                  _: 1
                }, 8, ["value", "modelValue"])
              ]),
              _createVNode(_component_UInput, {
                class: "!max-w-none flex-grow",
                label: "Фамилия",
                value: _ctx.last_name,
                modelValue: _ctx.last_name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.last_name) = $event))
              }, null, 8, ["value", "modelValue"]),
              _createVNode(_component_UInput, {
                class: "!max-w-none flex-grow",
                label: "Имя",
                value: _ctx.first_name,
                modelValue: _ctx.first_name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.first_name) = $event))
              }, null, 8, ["value", "modelValue"]),
              _createVNode(_component_UInput, {
                class: "!max-w-none flex-grow",
                label: "Отчество",
                value: _ctx.middle_name,
                modelValue: _ctx.middle_name,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.middle_name) = $event))
              }, null, 8, ["value", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_UButton, {
                label: "Отмена",
                color: "secondary",
                class: "mr-5",
                onClick: _ctx.closeModal
              }, null, 8, ["onClick"]),
              _createVNode(_component_UButton, {
                label: _ctx.successBtnLabel,
                disabled: _ctx.addBtnDisabled && !_ctx.loading,
                onClick: _ctx.addNewUser
              }, null, 8, ["label", "disabled", "onClick"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}