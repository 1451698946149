import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-383a8762"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay"
}
const _hoisted_2 = { class: "popup-container" }
const _hoisted_3 = { class: "popup-title" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UButton = _resolveComponent("UButton")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_UButton, {
              label: _ctx.cancelBtn,
              onClick: _withModifiers(_ctx.closePopup, ["prevent"]),
              class: "btn-cancel btn-long mr-4"
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_UButton, {
              label: _ctx.okBtn,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('success')), ["prevent"]))
            }, null, 8, ["label"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}