const URL_CLEAR = process.env.VUE_APP_URL
const URL = URL_CLEAR + '/api/v1'
export class API {
  /** @method get */
  static readonly REFRESH_TOKEN = URL_CLEAR + '/api/token/refresh/'

  /** @method post @method put */
  static readonly SIGNUP = URL + '/users/signup/'

  /** @method post @method put */
  static readonly SIGNUP_OTP = URL + '/users/signup/otp'

  /** @method post */
  static readonly AUTH_USERS = URL + '/users/auth/'

  /** @method post */
  static readonly AUTH_USERS_OTP = URL + '/users/auth/otp/'

  /** @method get @method post */
  static readonly AUTH_CHOOSECOMPANY = URL + '/users/auth/choosecompany/'

  /** @method get */
  static readonly SUPPORT_WORKER = URL + '/support/worker/'

  /** @method get  */
  static readonly SUPPORT_COWORKER = URL + '/users/company_staff/'

  /** @method get */
  static GET_SINGLE_WORKER(id: number | string) {
    return `${URL}/support/worker/${id}/`
  }

  /** @method patch */
  static CHANGE_WORKER_VERIFICATION_STATUS(id: number | string) {
    return `${URL}/support/worker/${id}/`
  }

  /** @method patch @method get */
  static COWORKER(id: number | string) {
    return `${URL}/support/company_staff/${id}/`
  }

  /** @method get */
  static GET_COWORKER(id: number | string) {
    return `${URL}/support/company_staff/${id}/get_info/`
  }

  /** @method get */
  static GET_COMPANY_OBJECT_AGREEMENTS(companyId: number | string) {
    return `${URL}/support/company/${companyId}/object_agreements/`
  }

  /** @method get */
  static GET_OBJECT_AGREEMENT(companyId: number | string, agreementId: number | string) {
    return `${URL}/support/company/${companyId}/object_agreements/${agreementId}`
  }

  /** @method patch */
  static UPDATE_OBJECT_AGREEMENT(companyId: number | string, agreementId: number | string) {
    return `${URL}/support/company/${companyId}/object_agreements/${agreementId}/`
  }

  /** @method post */
  static GENERATE_REPORT(id: number | string) {
    return `${URL}/support/company/${id}/object_agreements/report/`
  }

  /** @method post */
  static readonly GENERATE_DDS_UNLOAD = URL + '/support/dds/create_unload/'

  /** @method get */
  static readonly GET_REPORTINGS_HISTORY = URL + '/support/unloading/'

  /** @method get */
  static readonly GET_PROJECT = URL + '/support/project/'

  /** @method get */ /** @method post */
  static readonly GET_OBJECT = URL + '/support/object/'

  /** @method get */
  static GET_COMPANY_OBJECTS(id: number | string) {
    return URL + `/support/object/?company=${id}`
  }

  /** @method get */
  static readonly GET_ORDER = URL + '/support/order/'

  /** @method get */
  static GET_SINGLE_ORDER(id: number | string) {
    return `${URL}/support/order/${id}`
  }

  /** @method get */
  static readonly GET_UNIT_TYPES = URL + '/unit_types'

  /** @method get */
  static GET_SUBORDER_BY_ORDER(id: number | string) {
    return `${URL}/suborder/?order=${id}`
  }

  /** @method patch */
  static PATCH_SINGLE_ORDER(id: number | string) {
    return `${URL}/support/order/${id}/`
  }

  /** @method get */
  static GET_ORDER_DOCUMENTS(orderId: number | string) {
    return `${URL}/support/order/${orderId}/order_documents/`
  }

  /** @method get */
  static GET_FILE(file: string) {
    return `${URL_CLEAR}${file}`
  }

  /** @method patch */
  static UPDATE_SINGLE_ORDER(id: number | string) {
    return `${URL}/support/order/${id}/patch_suborder/`
  }

  /** @method patch */
  static UPDATE_SINGLE_DOC(id: number | string) {
    return `${URL}/support/order/${id}/patch_document/`
  }

  /** @method get */
  static readonly GET_COMPANIES_LIST_BILLS = URL + '/finance/transaction/get_billing_accounts/'

  /** @method get */
  static readonly GET_COMPANIES_LIST = URL + '/support/company_list/'

  /** @method get */
  static readonly GET_BILLS = URL + '/support/replenishment_account/'

  /** @method get */
  static readonly GET_LIST_ACCOUNTS = URL + '/support/bills/'

  // /** @method delete */
  // static DELETE_BILL(id: number | string) {
  //   return `${URL}/support/replenishment_account/${id}/`
  // }

  /** @method get */
  static GET_ACCOUNT_FILE(id: number | string) {
    return `${URL}/finance/replenishment_account/${id}/account_file/`
  }

  /** @method patch */
  static REPLENISHMENT_ACCOUNT(id: number | string) {
    return `${URL}/support/replenishment_account/${id}/`
  }

  /** @method post */
  static CREATE_BILLING_ACCOUNT = `${URL}/support/replenishment_account/`

  /** @method patch */
  static GET_COMPANY_INFO(id: number | string) {
    return `${URL}/finance/bill/${id}/get_balance_specific_company/`
  }

  /** @method patch */
  static GET_COMPANY_DEPOSIT_LIST(id: number | string) {
    return `${URL}/finance/transaction/${id}/get_company_transactions/`
  }

  /** @method post */
  static readonly FILL_BALANCE = URL + '/finance/transaction/send_transaction_sms/'

  /** @method post */
  static readonly DEBIT_BALANCE = URL + '/finance/transaction/send_transaction_sms/'

  /** @method post */
  static readonly CREATE_TRANSACTION = URL + '/finance/transaction/create_transaction/'

  /** @method get */
  static readonly GET_ENTITIES_LIST = URL + '/support/entity/'

  /** @method get */
  static GET_ENTITY_RECORD(id: number | string) {
    return `${URL}/support/entity/${id}/`
  }

  /** @method patch */
  static UPDATE_UNIT_AGREEMENT(id: number | string) {
    return `${URL}/support/entity/${id}/update_unit_agreement/`
  }

  /** @method patch */
  static UPDATE_ENTITY_RECORD(id: number | string) {
    return `${URL}/support/entity/${id}/`
  }

  /** @method patch */
  static UPDATE_ENTITY_SIGNER(id: number | string) {
    return `${URL}/support/entity/${id}/change_signer/`
  }

  /** @method patch */
  static UPDATE_ENTITY_ACCOUNT_DETAILS(id: number | string) {
    return `${URL}/support/entity/${id}/update_account_details/`
  }

  /** @method patch */
  static UPDATE_ENTITY_ACCOUNT_CONDITIONS(id: number | string) {
    return `${URL}/support/entity/${id}/update_conditions/`
  }

  /** @method patch */
  static UPDATE_ENTITY_ACCOUNT_REPRESENTATIVE(id: number | string) {
    return `${URL}/support/entity/${id}/update_representative/`
  }

  /** @method opst */
  static readonly UPLOAD_ENTITY_CHECK_LIST = URL + '/support/checklist/'

  /** @method opst */
  static readonly GET_CHECK_LIST = URL + '/support/checklist/'

  /** @method patch */
  static UPDATE_ENTITY_DIRECTOR(id: number | string) {
    return `${URL}/support/entity/${id}/update_director_details/`
  }

  /** @method patch */
  static UPDATE_ENTITY_TEMPLATES(id: number | string) {
    return `${URL}/support/entity/${id}/update_templates/`
  }

  /** @method post */
  static ADD_ENTITY_TEMPLATES(id: number | string) {
    return `${URL}/support/entity/${id}/add_template/`
  }

  /** @method post */
  static PREVIEW_TEMPLATE(id: number | string) {
    return `${URL}/support/entity/${id}/preview_template/`
  }

  /** @method delete */
  static REMOVE_TEMPLATE(id: number | string) {
    return `${URL}/support/entity/${id}/delete_template/`
  }

  /** @method get */
  static readonly GET_CUSTOMERS_LIST = URL + '/support/support_customer/'

  /** @method get */
  static GET_OTHER_WORKERS(id: number | string) {
    return `${URL}/support/company/${id}/other_workers/`
  }

  /** @method get */
  static GET_WORKERS_IN_COMPANY(id: number | string) {
    return `${URL}/support/company/${id}/workers/`
  }

  /** @method post */
  static readonly ADD_COMPANY_CUSTOMER = URL + '/support/support_customer/'

  /** @method post */
  static readonly ADD_CUSTOMER_TO_COMPANY = URL + '/support/support_customer/add_customer_to_company/'

  /** @method delete */
  static readonly REMOVE_CUSTOMER_FROM_COMPANY = URL + '/support/support_customer/remove_customer_from_company/'

  /** @method post */
  static ADD_WORKER_TO_COMPANY(id: number | string) {
    return `${URL}/support/company/${id}/workers/add/`
  }

  /** @method delete */
  static REMOVE_WORKER_FROM_COMPANY(id: number | string) {
    return `${URL}/support/company/${id}/workers/delete/`
  }

  /** @method get */
  static GET_COMPANY_PROCURATIONS(id: number | string) {
    return `${URL}/support/procurations/?company=${id}`
  }

  /** @method get */
  static GET_COMPANY_PROCURATIONS_REPRESENTATIVES(id: number | string) {
    return `${URL}/support/procurations/representatives/?company=${id}`
  }

  /** @method patch */
  static UPDATE_PROCURATION(id: number | string) {
    return `${URL}/support/procurations/${id}/`
  }

  /** @method post */
  static readonly NEW_PROCURATION = URL + '/support/procurations/'

  /** @method get */
  static GET_PROCURATION(id: number | string) {
    return `${URL}/support/procurations/${id}/`
  }

  /**  @method get */
  static GET_BILLING_ACCOUNT_DETAILS = `${URL}/finance/replenishment_account_details/`

  /** @method get */
  static PROFILE_WORKER_TRANSACTIONS(id: number | string) {
    return `${URL}/support/worker/${id}/transaction_history/`
  }

  /** @method post */
  static WORKER_FINANCIAL_REPORT(id: number | string) {
    return `${URL}/support/worker/${id}/finance_report/`
  }

  /** @method get */
  static readonly GET_USER_INFO = URL + '/users/me/'

  /** @method get */
  static readonly SPECIALIZATIONS = URL + '/specializations/'
}
