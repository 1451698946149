type APIProject = {
  budget: string
  company: number
  created: string
  description: string
  id: number
  last_updated: string // eslint-disable-line
  name: string
  spent: string
  status: 'active' | 'inactive'
}

type APIProjectData = {
  budget: string
  id: number
  name: string
  object_set: [APIObjectSet] // eslint-disable-line
  objects: number
  spent: string
  status: 'active' | 'inactive'
}

type APIObjectSet = {
  address: string
  budget: string
  created: string
  description: string
  id: number
  last_updated: string // eslint-disable-line
  name: string
  project: APIProject
  status: 'active' | 'inactive'
}

type APIObjectData = {
  address: string
  budget: string
  created: string
  description: string
  id: number
  last_updated: string // eslint-disable-line
  name: string
  project: APIProject
}

type APIOrders = {
  created: string
  finishDate: string
  id: number
  name: string
  object: APIObjectData
  start_date: string // eslint-disable-line
  status: string
  workers: number
}

type APIWorkersData = {
  agreement_url: [any] // eslint-disable-line
  date_joined: string // eslint-disable-line
  first_name: string // eslint-disable-line
  id: number // eslint-disable-line
  last_name: string // eslint-disable-line
  middle_name: string // eslint-disable-line
  personaldata: APIPersonalData // eslint-disable-line
} | null

type APIPersonalData = {
  added_by_registrator: boolean // eslint-disable-line
  card_number: string | null // eslint-disable-line
  citizenship: string
  created: string
  date_of_birth: string // eslint-disable-line
  date_of_issue: string // eslint-disable-line
  id: number
  index: string
  inn: string
  last_updated: string // eslint-disable-line
  passport_number: string // eslint-disable-line
  passport_photo_registered_address: string | null // eslint-disable-line
  passport_photo_reversal: string | null // eslint-disable-line
  passport_series: string // eslint-disable-line
  passport_whom_given: string // eslint-disable-line
  place_of_birth: string // eslint-disable-line
  registered_address: string // eslint-disable-line
  sex: string
  snils: string
  subdivision_code: string // eslint-disable-line
  user: number
  video: string | null
}

enum EProcurationStatus {
  active = 'active',
  expired = 'expired',
  check = 'on_check',
  declined = 'declined',
  expiring = 'expiring' // calculated status if doc will be expired in 30 days
}

enum EBillsStatus {
  paid_by_bank_transfer = 'paid_by_bank_transfer',
  not_paid = 'not_paid',
  deleted = 'deleted'
}

enum EObjectsStatus {
  active = 'active',
  archive = 'archive'
}

// type TObjects = {
//   address: string
//   budget: string
//   id: number
//   name: string
//   orders: number
//   project: APIProject
//   status: 'active' | 'archive'
// }

type TProcuration = {
  id: string
  procuration_number: string // eslint-disable-line
  procuration_release_date: string // eslint-disable-line
  procuration_exp_date: string // eslint-disable-line
  representative: any // eslint-disable-line
  status: EProcurationStatus
  showEditMenu?: boolean
  procuration: string
  decline_reason: string // eslint-disable-line
  company: number
}

type TBills = {
  id: number | string
  billing_account: string
  created: string
  date: string
  last_updated: string
  purpose_of_payment: string
  amount: string
  status: EBillsStatus
  account_file: any
  company: {
    id: number
    name: string
  }
  objects_list: [
    {
      id: number | string
      name: string
    }
  ]
  showEditMenu?: boolean
}

type TUnitAgreement = {
  agreement_with_unit_number?: string
  agreement_with_unit_date?: string
}

type TObjectAgreement = {
  id: number
  name: string
  object_agreements: {
    agreement_date: string
    agreement_number: string
    id: number
    object: number
    object_name: string
    status: EObjectsStatus
  }
  showEditMenu?: boolean
  status: EObjectsStatus
}

type TUnloadingReportings = {
  applications_only: boolean
  company: number | string
  created: string
  document_amount: number | string
  error: string
  extra_data: []
  file: string
  for_support: boolean
  id: number | string
  initiator: { first_name: string; last_name: string; middle_name: string }
  last_updated: string
  name: string
  period_begin: string
  period_end: string
  query_link: string
  status: string
}

const PROCURATION_STATUS_LABELS = {
  [EProcurationStatus.active]: 'Утверждено',
  [EProcurationStatus.expired]: 'Истекла',
  [EProcurationStatus.check]: 'На рассмотрении',
  [EProcurationStatus.declined]: 'Отклонено',
  [EProcurationStatus.expiring]: 'Истекает'
}

const BILLS_STATUS_LABELS = {
  [EBillsStatus.paid_by_bank_transfer]: 'Оплачено по безналу',
  [EBillsStatus.not_paid]: 'Счет не оплачен',
  [EBillsStatus.deleted]: 'Удален'
}

const OBJECTS_STATUS_LABELS = {
  [EObjectsStatus.active]: 'Активен',
  [EObjectsStatus.archive]: 'Завершен'
}

export {
  APIProject,
  APIProjectData,
  APIObjectSet,
  APIObjectData,
  APIOrders,
  APIWorkersData,
  APIPersonalData,
  EProcurationStatus,
  EBillsStatus,
  EObjectsStatus,
  PROCURATION_STATUS_LABELS,
  BILLS_STATUS_LABELS,
  OBJECTS_STATUS_LABELS,
  TUnloadingReportings,
  TProcuration,
  TBills,
  TObjectAgreement,
  TUnitAgreement
}
