<template>
  <header class="flex-container">
    <h1 class="text-3xl font-medium">{{ title }}</h1>
    <slot></slot>
    <!-- <div class="header-actions-container">
          <button class="header-btn" type="btn">
            <img class="header-btn__image" src="/icons/messages.svg" alt="Сообщения">
          </button>
          <button class="header-btn" type="btn">
            <img class="header-btn__image" src="/icons/notifications.svg" alt="Уведомления">
          </button>
          <div class="header-profile">
            <button class="header-btn" type="btn" @click="showProfileActions = !showProfileActions">
              <img class="header-btn__image" src="/icons/profile.svg" alt="Профиль">
            </button>
            <div class="profile-dropdown" v-if="showProfileActions">
              <button type="button" @click="logout">Выйти</button>
            </div>
          </div>
        </div> -->
  </header>
</template>

<script>
export default {
  name: 'main-page-header',
  props: ['title'],
  data() {
    return {
      showProfileActions: false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-actions-container {
  display: flex;
}

.flex-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.header-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  overflow: hidden;
  text-align: center;
  margin-left: 10px;

  &__image {
    width: 20px;
    height: auto;
    vertical-align: top;
  }

  &:hover {
    cursor: pointer;
  }
}

.header-profile {
  position: relative;
}

.profile-dropdown {
  position: absolute;
  right: 0;
}

h1 {
  text-align: left;
}
</style>
