import { ref } from 'vue'
import moment from 'moment'
import { defineStore } from 'pinia'
import WorkerService from '@/services/worker.service'

function formatDate(date: Date) {
  return moment(date).format('DD.MM.YYYY') || null
}

function normalizeWorker(data: any, specializations: {id: string, name: string}[]) {
  if (data?.specialization) {
    const _item = specializations.find((specialization: {id: string, name: string}) => {
      return specialization.name === data.specialization
    })
    data.specialization = _item?.id
  }
  if (data?.passport_information?.length) {
    data.passport_information = data.passport_information.map((p: any) => {
      if (p.date_of_issue) {
        p.date_of_issue = formatDate(p.date_of_issue)
      }
      if (p.passport_expires) {
        p.passport_expires = formatDate(p.passport_expires)
      }
      return p
    })
  }
  if (data?.personaldata) {
    if (data?.personaldata.date_of_birth) {
      data.personaldata.date_of_birth = formatDate(data.personaldata.date_of_birth)
    }
    if (data.personaldata?.sex === 'Муж') {
      data.personaldata.sex = 'M'
    }
    if (data.personaldata?.sex === 'Жен') {
      data.personaldata.sex = 'F'
    }
  }
  return data
}

export const useWorkersStore = defineStore('workers', () => {
  const specializations = ref([])
  async function fetchWorker(id: string) {
    try {
      await fetchSpecializations()
      const { data } = await WorkerService.getSingleWorker(id)
      return data ? normalizeWorker(data, specializations.value) : {}
    } catch (error) {
      return {}
    }
  }

  async function fetchSpecializations() {
    try {
      if (specializations.value.length) {
        return specializations.value
      }
      const { data } = await WorkerService.getSpecializations()
      specializations.value = data
      return data
    } catch (error) {
      return []
    }
  }

  async function updateWorker(id: string, payload: any) {
    try {
      const { data } = await WorkerService.patchWorker(id, payload)
      return data || {}
    } catch (error) {
      const data = error?.response?.data
      throw data || {}
    }
  }

  function $reset() {
    specializations.value = []
  }

  return {
    fetchWorker,
    fetchSpecializations,
    updateWorker,
    specializations,
    $reset
  }
})
